// i18n.js
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import enTranslation from "../locales/en.json"; // Import your language JSON files
import roTranslation from "../locales/ro.json";

i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        resources: {
            ro: { translation: roTranslation },
            en: { translation: enTranslation },
        },
        fallbackLng: "ro",
        debug: true,
        interpolation: {
            escapeValue: false,
        },
    });

export default i18n;